<style>
.input-readonly > div > .vs-inputx {
  background-color: #e6e6e6;
}
.bg-dark {
  background-color: #e6e6e6 !important;
}
</style>
<template>
  <div class="vx-col w-5/6 ml-auto mr-auto">
    <span>
      <h4 class="mb-5">Detail Payment</h4>
    </span>
    <div class="vx-row mb-2">
      <div class="vx-col w-full">
        <label class="vs-input--label">Supplier</label>
        <multiselect
          class="selectExample"
          v-model="supplier"
          :options="optionSupplier"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Name"
          :disabled="false"
        >
          <!-- <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.Name }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.Name }}</span>
            </div>
          </template> -->
        </multiselect>
      </div>
      <div class="vx-col w-full">
        <label class="vs-input--label">Payment Proposal</label>
        <multiselect
          class="selectExample"
          v-model="paymentProposal"
          :options="optionPaymentProposal"
          :multiple="true"
          :allow-empty="true"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Code"
          @search-change="getOptionPaymentProposal"
          :disabled="false"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{ dt.option.Code }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{ dt.option.Code }}</span>
              <br />
              <span class="option__desc">
                Due Date : {{ dateFormat(dt.option.DueDate) }}</span
              >
              <br />
              <span class="option__desc">
                Paid Proposal ({{ priceFormat(dt.option.PaidValue) }})</span
              >
            </div>
          </template>
        </multiselect>
      </div>

      <div class="vx-col w-full">
        <template v-for="proposal in paymentProposal">
          <div v-bind:key="proposal.ID">
            <vs-divider style="width: 100%"
              ><b>{{ proposal.Code }}</b></vs-divider
            >
            Code : {{ proposal.PurchaseInvoiceCode }}
            <feather-icon
              v-on:click="showInvoice(proposal.PurchaseInvoiceID)"
              icon="InfoIcon"
              svgClasses="h-4 w-4 clickable"
            /><br />
            Due Date : {{ dateFormat(proposal.DueDate) }}<br />
            Paid Proposal : {{ priceFormat(proposal.PaidValue) }}<br />
            <vs-divider style="width: 100%">Attachment</vs-divider>
            <div
              class="vx-row mb-3 mt-6 w-4/4"
              style="width: 100%; margin-left: 0%"
            >
              <table class="vs-table vs-table--tbody-table">
                <template v-for="(tr, i) in piAttachment(proposal.ID)">
                  <tr
                    class="tr-values vs-table--tr tr-table-state-null selected"
                    v-bind:key="tr.name + i"
                  >
                    <td class="td vs-table--td">{{ tr.name }}</td>
                    <td class="td vs-table--td">
                      <vx-tooltip text="Show" v-if="tr.path != '' && canView">
                        <vs-button
                          type="line"
                          icon-pack="feather"
                          @click.stop="downloadFileAwsS3(tr.path)"
                          icon="icon-eye"
                        />
                      </vx-tooltip>
                    </td>
                    <!-- <td class="td vs-table--td">
                      <template>
                        <vx-tooltip text="Delete">
                          <vs-button
                            type="line"
                            icon-pack="feather"
                            @click.stop="handleDeleteAttachment(i, tr.id)"
                            icon="icon-trash"
                          />
                        </vx-tooltip>
                      </template>
                    </td> -->
                  </tr>
                </template>
              </table>
            </div>
            <vs-divider style="width: 100%">Note</vs-divider>
            <template v-for="note in piNotes(proposal.ID)">
              <b v-bind:key="note"
                >{{ note }}
                <br />
              </b>
            </template>
            <vs-divider style="width: 100%">Comment</vs-divider>
            {{ piComment(proposal.ID) }}
            <vs-divider style="width: 100%"></vs-divider>
            <vs-divider style="width: 100%"></vs-divider>
          </div>
        </template>
      </div>
      <div class="vx-col w-full">
        <label class="vs-input--label">Operating Unit Bank</label>
        <multiselect
          class="selectExample"
          v-model="operatingUnitBank"
          :options="optionBankOperatingUnit"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="Name"
          :disabled="true"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title">{{
                bank.filter((v) => {
                  return v.ID == dt.option.BankID;
                })[0].Name +
                " " +
                dt.option.AccountNumber +
                " (" +
                dt.option.AccountName +
                ")"
              }}</span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">{{
                bank.filter((v) => {
                  return v.ID == dt.option.BankID;
                })[0].Name +
                " " +
                dt.option.AccountNumber +
                " (" +
                dt.option.AccountName +
                ")"
              }}</span>
            </div>
          </template>
        </multiselect>
      </div>
      <div class="vx-col w-full">
        <label class="vs-input--label">Supplier Bank</label>
        <multiselect
          class="selectExample"
          v-model="supplierBank"
          :options="optionBankSupplier"
          :multiple="false"
          :allow-empty="false"
          :group-select="false"
          :max-height="160"
          :limit="4"
          placeholder=" Type to search"
          track-by="ID"
          label="BankName"
          :disabled="true"
        >
          <template slot="singleLabel" slot-scope="dt">
            <span class="option__desc">
              <span class="option__title"
                >{{ dt.option.BankName }} {{ dt.option.AccountNumber }} ({{
                  dt.option.AccountName
                }})
              </span>
            </span>
          </template>

          <template slot="option" slot-scope="dt">
            <div class="option__desc">
              <span class="option__title">
                {{ dt.option.BankName }} {{ dt.option.AccountNumber }} ({{
                  dt.option.AccountName
                }})
              </span>
            </div>
          </template>
        </multiselect>
      </div>
      <vs-divider style="width: 100%; margin-left: 2%"><b>Notes</b></vs-divider>
      <template v-for="note in notes">
        <b v-bind:key="note"
          >{{ note }}
          <br />
        </b>
      </template>
    </div>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";

export default {
  components: {
    Datepicker,
  },
  created() {},
  data() {
    return this.initialState();
  },
  props: {
    selected: Object,
  },
  methods: {
    initialState() {
      return {
        search: "",
        supplier: null,
        operatingUnitBank: null,
        supplierBank: null,
        bank: [],
        paymentProposal: [],
        optionSupplier: [],
        optionPaymentProposal: [],
        optionBankOperatingUnit: [],
        optionBankSupplier: [],
        notes: [],
        purchaseInvoice: [],
      };
    },
    piNotes(id) {
      let pi = this.purchaseInvoice.filter(function (pi) {
        return pi.ID == id;
      });
      let notes = [];
      if (pi.length > 0) {
        notes = pi[0].purchaseInvoice.Notes.toString().split(";");
      }
      return notes;
    },
    piComment(id) {
      let pi = this.purchaseInvoice.filter(function (pi) {
        return pi.ID == id;
      });
      let comment = "";
      if (pi.length > 0) {
        comment = pi[0].purchaseInvoice.CommentInvoice;
      }
      return comment;
    },
    piAttachment(id) {
      let pi = this.purchaseInvoice.filter(function (pi) {
        return pi.ID == id;
      });
      let attachment = [];
      if (pi.length > 0) {
        attachment = pi[0].attachment;
      }
      return attachment;
    },
    showInvoice(id) {
      this.$emit("detailPurchaseInvoice", id);
    },
    handleSubmit() {
      if (this.paymentProposal.length < 1) {
        this.$vs.notify({
          title: "Error",
          text: "please select payment proposal first ",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
        return;
      }
      if (this.operatingUnitBank == null) {
        this.$vs.notify({
          title: "Error",
          text: "please select operating unit bank first ",
          color: "danger",
          position: "top-right",
          iconPack: "feather",
          icon: "icon-check",
        });
      }

      // //console.log(this.operatingUnitBank,this.supplierBank)

      let line = [];
      let total = parseFloat(0);
      this.paymentProposal.map((v) => {
        total += parseFloat(v.PaidValue);
        line.push({
          proposal_id: v.ID,
          code: v.Code,
        });
      });
      const params = {
        id: this.selected.ID,
        supplier_code: this.supplier.Code,
        supplier_name: this.supplier.Name,
        due_date: moment(this.paymentProposal[0].DueDate).format("YYYY-MM-DD"),
        bank_id: this.operatingUnitBank.BankID,
        bank_name: this.bank.filter((v) => {
          return this.operatingUnitBank.BankID == v.ID;
        })[0].Name,
        account_number: this.operatingUnitBank.AccountNumber,
        account_name: this.operatingUnitBank.AccountName,
        account_branch: this.operatingUnitBank.BranchName,
        to_bank_id: this.supplierBank.BankID,
        to_bank_name: this.supplierBank.BankName,
        to_account_number: this.supplierBank.AccountNumber,
        to_account_name: this.supplierBank.AccountName,
        to_account_branch: this.supplierBank.BankBranch,
        total: total,
        line: line,
      };
      this.$vs.loading();
      //console.log(params)
      this.$http
        .post("/api/v1/purchase-payment-request/update", params)
        .then((result) => {
          //console.log(result)
          this.$vs.loading.close();
          if (result.code == 200) {
            this.handleClose();
            this.$vs.notify({
              title: "Success",
              text: "",
              color: "success",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to update",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
        })
        .catch((e) => {});
    },
    handleShowAttachment(selected) {
      console.log(selected);
      if (selected.path != "" && selected.path !== undefined) {
        window.open(selected.path);
      } else if (selected.PathFile != "" && selected.PathFile !== undefined) {
        window.open(selected.PathFile);
      }
    },
    handleClose() {
      window.scrollTo(0, 0);
      this.$emit("close");
    },
    dateFormat(value) {
      return moment(String(value)).format("DD/MM/YYYY");
    },
    getOptionPaymentProposal(query) {
      //console.log(this.supplier)
      this.$http
        .get("/api/v1/purchase-payment-proposal", {
          params: {
            status: 1,
            request_code: this.selected.Code,
            search: query,
            length: 100,
            still_empty: 1,
            // page: params.page,
            // order: params.sort,
            // sort: params.order,
            supplier_code: this.supplier.Code,
          },
        })
        .then((r) => {
          this.optionPaymentProposal = r.data.purchasePaymentProposal;
          //console.log(r)
        })
        .catch((e) => {
          //console.log(e)
        });
    },
    getOptionBankOperationUnit() {
      this.$http
        .get("/api/v1/purchase-payment-proposal/bank-operating-unit")
        .then((r) => {
          this.optionBankOperatingUnit = r.data.dataOperatingUnitBank;
          this.bank = r.data.dataBank;
          // //console.log(r)
          if (this.selected.ID) {
            r.data.dataOperatingUnitBank.map((v) => {
              if (v.AccountNumber == this.selected.AccountNumber) {
                this.operatingUnitBank = v;
              }
            });
          }
        })
        .catch((e) => {
          //console.log(e)
        });
    },
    getOptionSupplierBank() {
      this.$http
        .get("/api/v1/pi/supplier-account/" + this.supplier.ID)
        .then((r) => {
          this.optionBankSupplier = r.data.account;
          //console.log(this.selected)
          if (this.selected.ID) {
            r.data.account.map((v) => {
              if (
                v.BankName == this.selected.ToBankName &&
                v.AccountNumber == this.selected.ToAccountNumber
              ) {
                this.supplierBank = v;
              }
            });
          }
          //console.log(r)
        })
        .catch((e) => {
          //console.log(e)
        });
    },
    getData() {
      this.$vs.loading();
      this.$http
        .get("/api/v1/purchase-payment-request/line/" + this.selected.ID)
        .then((result) => {
          // this.$vs.loading.close();
          result.data.purchasePaymentProposal.map((v) => {
            this.paymentProposal.push(v);
          });
          this.purchaseInvoice = [];
          result.data.purchaseInvoice.map((v) => {
            this.purchaseInvoice.push(v);
          });
          this.notes = this.selected.Notes.toString().split(";");
          //console.log(result)
        })
        .catch((e) => {
          this.$vs.loading.close();
        });
    },
    getOptionSupplier(query) {
      if (query.length <= 2) {
        return;
      }
      this.$vs.loading();
      this.$http
        .get("/api/v1/master/suppliers", {
          params: {
            order: "name",
            sort: "asc",
            search: query,
          },
        })
        .then((resp) => {
          if (resp.code == 200) {
            this.optionSupplier = resp.data.records;
            if (this.selected.ID) {
              resp.data.records.map((v) => {
                if (v.Code == this.selected.SupplierCode) {
                  this.supplier = v;
                }
              });
            }
          } else {
            this.$vs.notify({
              title: "Error",
              text: "Failed to get Supplier option",
              color: "danger",
              position: "top-right",
              iconPack: "feather",
              icon: "icon-check",
            });
          }
          this.$vs.loading.close();
        });
    },
  },
  mounted() {
    if (this.selected.SupplierCode != "ONETIME") {
      this.getOptionSupplier(this.selected.SupplierName);
    } else {
      console.log(this.selected, "Supplier this selected");
      const supplier = [
        {
          Name: this.selected.SupplierName,
          Code: this.selected.SupplierCode,
        },
      ];
      this.supplier = supplier;

      const bankSupplier = [
        {
          BankName: this.selected.ToBankName,
          AccountNumber: this.selected.ToAccountNumber,
          AccountName: this.selected.ToAccountName,
        },
      ];
      this.supplierBank = bankSupplier;
    }
    this.getOptionBankOperationUnit();
    this.getData();
    this.getOptionSupplier(this.selected.SupplierName);
    // this.getOptionPaymentTerm();
    // this.getOptionSupplier();
    // this.getOptionWarehouse();
  },
  computed: {
    canView() {
      return this.$store.getters["user/hasPermissions"]("view");
    },
  },
  watch: {
    "supplier.ID"() {
      this.getOptionSupplierBank();
    },
    "selected.ID"() {
      //console.log("selected change")
      this.getData();
      this.getOptionSupplier(this.selected.SupplierName);
      this.getOptionSupplierBank();
    },
  },
};
</script>
<style scoped>
.pagination {
  display: flex;
  margin: 0.25rem 0.25rem 0;
}

.pagination button {
  flex-grow: 1;
}

.pagination button:hover {
  cursor: pointer;
}
</style>
